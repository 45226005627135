.markdown-content {
  max-width: 100%;
  margin: auto;
  font-family: sans-serif;
  line-height: 1.6;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Headings */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: #333;
}

.markdown-content h1 {
  font-size: 2.5em;
  margin-top: 20px;
}

.markdown-content h2 {
  font-size: 2em;
  margin-top: 30px;
}

.markdown-content h3 {
  font-size: 1.75em;
  margin-top: 20px;
}

.markdown-content h4 {
  font-size: 1.5em;
  margin-top: 20px;
}

.markdown-content h5 {
  font-size: 1.25em;
  margin-top: 20px;
}

.markdown-content h6 {
  font-size: 1em;
  margin-top: 20px;
}

/* Paragraphs */
.markdown-content p {
  margin: 20px 0;
  color: #555;
}

/* Links */
.markdown-content a {
  color: #0066cc;
  text-decoration: none;
  border-bottom: 1px solid #0066cc;
}

.markdown-content a:hover {
  color: #004080;
  border-bottom: 1px solid #004080;
}

/* Images */
.markdown-content img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

/* Lists */
.markdown-content ul,
.markdown-content ol {
  padding-left: 20px; /* Adjusted for better mobile readability */
  margin: 10px 0;
}

.markdown-content ul li,
.markdown-content ol li {
  margin: 10px 0;
}

/* Blockquotes */
.markdown-content blockquote {
  border-left: 5px solid #ccc;
  padding: 10px 20px;
  color: #666;
  background: #f9f9f9;
  font-style: italic;
}

/* Inline code */
.markdown-content code {
  background: #f4f4f4;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
}

/* Code blocks */
.markdown-content pre {
  background: #282c34;
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
  padding: 10px;
  overflow-x: auto; /* Ensure horizontal scroll */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  white-space: pre-wrap; /* Maintain pre-wrap for code blocks */
}

.markdown-content pre code {
  background: none;
  color: inherit;
  border-radius: 0;
  font-family: 'Courier New', Courier, monospace;
}

/* Tables */
.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.markdown-content table th,
.markdown-content table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.markdown-content table th {
  background: #f4f4f4;
  font-weight: bold;
}

/* Horizontal rules */
.markdown-content hr {
  border: none;
  border-top: 2px solid #eee;
  margin: 30px 0;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .markdown-content {
      padding: 20px;
  }

  .markdown-content h1 {
      font-size: 2em;
      margin-top: 20px;
  }

  .markdown-content h2 {
      font-size: 1.75em;
      margin-top: 25px;
  }

  .markdown-content h3 {
      font-size: 1.5em;
      margin-top: 20px;
  }

  .markdown-content h4 {
      font-size: 1.25em;
      margin-top: 20px;
  }

  .markdown-content h5 {
      font-size: 1.1em;
      margin-top: 20px;
  }

  .markdown-content h6 {
      font-size: 1em;
      margin-top: 20px;
  }

  .markdown-content blockquote {
      padding: 10px;
  }

  .markdown-content pre {
      padding: 8px;
  }

  .markdown-content table {
      font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .markdown-content {
      padding: 15px;
  }

  .markdown-content h1 {
      font-size: 1.8em;
      margin-top: 15px;
  }

  .markdown-content h2 {
      font-size: 1.5em;
      margin-top: 20px;
  }

  .markdown-content h3 {
      font-size: 1.3em;
      margin-top: 15px;
  }

  .markdown-content h4 {
      font-size: 1.1em;
      margin-top: 15px;
  }

  .markdown-content h5 {
      font-size: 1em;
      margin-top: 15px;
  }

  .markdown-content h6 {
      font-size: 0.9em;
      margin-top: 15px;
  }

  .markdown-content blockquote {
      padding: 8px;
  }

  .markdown-content pre {
      padding: 6px;
  }

  .markdown-content table {
      font-size: 0.85em;
  }
}

/* src/components/Sidebar.css */

.sideNav {
  background-color: #333;
  padding: 0px;
  height: 100%;
  border-right: 1px solid #ddd;
  overflow-y:scroll ;
}
  .sidebar ol {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    margin: 10px 0;
  }
  
  .sidebar a {
    text-decoration: none;
    color: white;
  }
  
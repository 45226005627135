.layout-header{
    width: 100%;
    height: 300px;
    background-color: rgb(21, 19, 19);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 64px;
    color: white;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .layout-header {
      height: 250px; 
      font-size: 48px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .layout-header {
      height: 250px; 
      font-size: 28px; 
    }
  }
